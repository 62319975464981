import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { takeUntil, map } from "rxjs/operators";
import { interval, Subscription, Subject } from "rxjs";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastsService } from "@presentation/utils/molecules/toasts/toasts.service";
import { SendVerificationOtpEmailUsecase } from "@domain/usecases/send-verification-otp-email-usecase";
import { ValidateVerificationOtpEmailUsecase } from "@domain/usecases/validate-verification-otp-email-usecase";
import { AppResourcesService } from "@core/resources/app-resources.service";

@Component({
  selector: "app-otp-code-validation",
  templateUrl: "./otp-code-validation.component.html",
  styleUrls: ["./otp-code-validation.component.css"],
})
export class OtpCodeValidationComponent implements OnInit {
  otp: string = "";
  otpView: boolean = true;
  email: string = "";
  timeLeft: number = 60;
  showTimer: boolean = true;
  subscription?: Subscription;
  stopTimer$ = new Subject<void>();
  otpIsValid: boolean = false;
  iconSuccess = AppResourcesService.icons.greenCheck;

  @ViewChild("ngOtpInput") ngOtpInputRef: any;

  constructor(
    private toastsService: ToastsService,
    private _location: Location,
    private router: Router,
    private sendVerificationOtpEmailUsecase: SendVerificationOtpEmailUsecase,
    private validateVerificationOtpEmailUsecase: ValidateVerificationOtpEmailUsecase
  ) {
    this.getScreenParameters();
  }

  ngOnInit(): void {
    this.startTimer();
  }

  getScreenParameters() {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.email = navigation.extras.state["email"];
    }
  }

  onOtpChange(otp: string) {
    this.otp = otp;
    if (otp.length != 4) return;
    this.verifictionOtpFlow();
  }

  async verifictionOtpFlow() {
    this.toastsService.showLoadingToast();
    let validationResult: boolean | null =
      await this.validateVerificationOtpEmail(this.email, this.otp);
    this.toastsService.hideLoadingToast();
    if (validationResult == null) {
      this.otpIsValid = false;
      this.closeNotification();
      return;
    }
   if (!validationResult) {
      this.otpIsValid = false;
      this.closeNotification();
      return;
    }
  
      this.otpIsValid = true;
      this.otpView = false;
      return;
    
  }

  continue() {
    this.router.navigate(["/password-reset/new-password"], {
      state: { email: this.email },
    });
  }

  closeNotification() {
    setTimeout(() => {
      this.otpIsValid = true;
    }, 2000);
  }

  async validateVerificationOtpEmail(
    email: string,
    otp: string
  ): Promise<boolean | null> {
    let result = await this.validateVerificationOtpEmailUsecase.execute({
      email: email,
      otp: otp,
    });
    return result.fold(
      (l) => null,
      (r) => r
    );
  }

  async reSendVerificationOtpEmailFlow() {
    this.toastsService.showLoadingToast();
    let sendOtpResult = await this.reSendVerificationOtpEmail(this.email);
    this.toastsService.hideLoadingToast();
    if (!sendOtpResult) {
      this.toastsService.showInfoToast(
        "No se pudo reenviar el correo. Intenta nuevamente."
      );
      return;
    }

    this.resetTimer();
  }

  async reSendVerificationOtpEmail(email: string): Promise<boolean> {
    let result = await this.sendVerificationOtpEmailUsecase.execute(email);
    return result.fold(
      (l) => false,
      (r) => true
    );
  }

  startTimer(): void {
    this.subscription = interval(1000)
      .pipe(
        takeUntil(this.stopTimer$),
        map((val) => 60 - val)
      )
      .subscribe((val) => {
        this.timeLeft = val;
        if (val === 0) {
          this.showTimer = false;
          this.stopTimer$.next();
        }
      });
  }

  resetTimer(): void {
    this.showTimer = true;
    this.timeLeft = 60;
    this.stopTimer$.next();
    this.otp = "";
    this.startTimer();
    this.ngOtpInputRef.setValue("");
  }

  goBack() {
    this._location.back();
  }
}
