<div class="content">
  <div class="content-header-row">
    <span class="text-back" (click)="goBack(2)"> <i class="pi pi-chevron-left"></i> Volver</span>
    <div class="title-container">
      <h1 class="content-title">
        Ahora ingresa tu <br />
        <span class="new-password-title">nueva contraseña</span>
      </h1>
    </div>
  </div>
  <div class="form-container">
    <form class="form" [formGroup]="form">
      <div class="field">
        <app-textfield [label]="'Nueva contraseña'" [placeHolder]="'Ingresar'" [width]="'100%'" [height]="'56px'"
          [iconPath]="'assets/icons/password_icon.svg'" [type]="'password'" (fieldFocused)="openPasswordModal()"
          formControlName="password"></app-textfield>
      </div>
      <div class="field">
        <app-textfield [label]="'Confirmar contraseña'" [placeHolder]="'Ingresar'" [width]="'100%'" [height]="'56px'"
          [iconPath]="'assets/icons/password_icon.svg'" [type]="'password'"
          formControlName="confirmPassword"></app-textfield>
        <div class="error-message" *ngIf="form.hasError('stringsNotMatch')">
          <img src="\assets\icons\error.svg" alt="" />
          La nueva contraseña no coincide
        </div>
      </div>
    </form>
  </div>
  <div class="form-buttons">
    <div class="button-container">
      <app-button [text]="'Actualizar contraseña'" [width]="'100%'" [height]="'56px'" [disabled]="!this.form.valid"
        (click)="updatePasswordFlow()"></app-button>
    </div>
  </div>
</div>
<div *ngIf="showPasswordModal == true">
  <app-password-modal></app-password-modal>
</div>