import { Injectable } from "@angular/core";
import { Either } from "../../core/helpers/either";
import { Failure } from "../../core/helpers/failure";
import { UseCase } from "../../core/helpers/use-case";
import { UserRepository } from "@domain/repository/user-repository";

@Injectable({
    providedIn: 'root'
})
export class SendVerificationOtpEmailUsecase implements UseCase<void, string>{
    constructor(private userRepository: UserRepository) { }

    async execute(params: string): Promise<Either<Failure, void>> {
        return await this.userRepository.sendVerificationOtpEmail(params);
    }
}