import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PasswordResetComponent } from "./password.component";
import { EmailToResetComponent } from "./pages/email-to-reset/email-to-reset.component";
import { OtpCodeValidationComponent } from "./pages/otp-code-validation/otp-code-validation.component";
import { NewPasswordComponent } from "./pages/new-password/new-password.component";

const routes: Routes = [
  {
    path: "",
    component: PasswordResetComponent,
    children: [
      {
        path: "",
        component: EmailToResetComponent,
      },
      {
        path: "otp-verification",
        component: OtpCodeValidationComponent,
      },
      {
        path: "new-password",
        component: NewPasswordComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PasswordResetRoutingModule {}
