import { Injectable } from "@angular/core";
import { Either } from "../../core/helpers/either";
import { Failure } from "../../core/helpers/failure";
import { UseCase } from "../../core/helpers/use-case";
import { UserRepository } from "@domain/repository/user-repository";
import { UpdateUserPasswordBody } from "@domain/models/update-user-password-body";

@Injectable({
    providedIn: 'root'
})
export class UpdateUserPasswordUsecase implements UseCase<void, UpdateUserPasswordBody>{
    constructor(private userRepository: UserRepository) { }

    async execute(params: UpdateUserPasswordBody): Promise<Either<Failure, void>> {
        return await this.userRepository.updateUserPassword(params);
    }
}