import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ColorService } from '../../../../../../../core/services/color.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastsService } from '@presentation/utils/molecules/toasts/toasts.service';
import { VerifyEmailExistanceUsecase } from '@domain/usecases/verify-email-existance-usecase';
import { AppResourcesService } from '../../../../../../../core/resources/app-resources.service';
import { SendVerificationOtpEmailUsecase } from '@domain/usecases/send-verification-otp-email-usecase';

@Component({
  selector: 'app-email-to-reset',
  templateUrl: './email-to-reset.component.html',
  styleUrl: './email-to-reset.component.css'
})
export class EmailToResetComponent {
  faChevronLeft = faChevronLeft

  emailIcon: string = ""
  infoIcon: string = ""
  emailFieldError: string = ""

  emailForm: FormGroup;
  colorService: ColorService = new ColorService()


  constructor(
    private toastsService: ToastsService,
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router,
    private verifyEmailExistanceUsecase: VerifyEmailExistanceUsecase,
    private sendVerificationOtpEmailUsecase: SendVerificationOtpEmailUsecase,
  ) {
    this.emailIcon = AppResourcesService.icons.email_form
    this.infoIcon = AppResourcesService.icons.info
    this.emailForm = this.formBuilder.group({
      email: new FormControl("", [Validators.required, Validators.email,])
    });
  }

  async onContinueButtonTap() {
    if (!this.emailForm.valid) return;

    this.toastsService.showLoadingToast()
    let emailExist: boolean | null = await this.verifyEmailExistance(this.emailForm.value.email)
    if (emailExist == null) {
      this.toastsService.hideLoadingToast()
      this.toastsService.showInfoToast("No se pudo comprobar el correo. Intenta nuevamente.")
      return;
    }

    if (!emailExist) {
      this.toastsService.hideLoadingToast()
      this.emailFieldError = "* Correo electrónico no ha sido registrado"
      return;
    }

    let sendOtpResult = await this.sendVerificationOtpEmail(this.emailForm.value.email)
    this.toastsService.hideLoadingToast()
    if (!sendOtpResult) {
      this.toastsService.showInfoToast("No se pudo enviar un correo para recuperar la contraseña. Intenta nuevamente.")
      return;
    }

    this.router.navigate(["/password-reset/otp-verification"], { state: { "email": this.emailForm.value.email } })
  }

  onEmailFieldChange() {
    if (this.emailFieldError == "") return;
    this.emailFieldError = "";
  }

  async verifyEmailExistance(email: string): Promise<boolean | null> {
    let result = await this.verifyEmailExistanceUsecase.execute(email)
    return result.fold(
      (l) => null,
      (r) => r,
    )
  }

  async sendVerificationOtpEmail(email: string): Promise<boolean> {
    let result = await this.sendVerificationOtpEmailUsecase.execute(email);
    return result.fold(
      (l) => false,
      (r) => true,
    )
  }

  onPop() {
    this.location.back()
  }
}
