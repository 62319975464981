<div *ngIf="otpView" class="container">
  <div class="content-header-row">
    <span (click)="goBack()" class="text-back"> <i class="pi pi-chevron-left"></i>Volver</span>
    <h1 class="content-title">
      Ingresa el código<br />
      <span class="empresa-title">que enviamos</span>
    </h1>
  </div>
  <span class="secundary-text">A tu correo electrónico {{email}}</span>
  <div class="otp-container">
    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{
        length: 4,
        inputClass: 'otp-input',
        containerClass: 'otp-container'
      }"></ng-otp-input>
  </div>
  <div *ngIf="showTimer">
    <span class="secundary-text">Podrás seleccionar un nuevo código en</span>
    <span class="timmer">{{ timeLeft }} seg</span>
  </div>
  <div *ngIf="otp.length === 4 && !otpIsValid" class="alert-container">
    <img src="\assets\icons\alert.png" alt="" />
    <div class="msg-container">
      <span>El código de verificación es incorrecto.</span>
      <span>Valídalo e ingrésalo nuevamente.</span>
    </div>
  </div>
  <div *ngIf="!showTimer">
    <span class="secundary-text">Se termino el tiempo</span>
    <span class="reset-timmer" (click)="reSendVerificationOtpEmailFlow()">Enviar nuevo código</span>
  </div>
</div>
<app-metamap-modal
  *ngIf="!otpView"
  [tittleText]="'Código de verificación  exitoso'"
  [contentText]="''"
  [modalIcon]="iconSuccess"
  [showButtons]="true"
  [twoButtons]="false"
  [buttonRigthText]="'Continuar'"
  (ButtonRightEvent)="continue()"
  [widthButton]="'200px'"
  [bodyText]="false"
></app-metamap-modal>