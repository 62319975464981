import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PasswordResetRoutingModule } from "./password-routing.module";
import { PasswordResetComponent } from "./password.component";
import { MatIcon } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { EmailToResetComponent } from "./pages/email-to-reset/email-to-reset.component";
import { OtpCodeValidationComponent } from "./pages/otp-code-validation/otp-code-validation.component";
import { ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgOtpInputModule } from "ng-otp-input";
import { UtilsModule } from "../../../../utils/utils.module";
import { NewPasswordComponent } from "./pages/new-password/new-password.component";

@NgModule({
  declarations: [
    PasswordResetComponent,
    EmailToResetComponent,
    OtpCodeValidationComponent,
    NewPasswordComponent,
  ],
  imports: [
    NgOtpInputModule,
    CommonModule,
    PasswordResetRoutingModule,
    MatButtonModule,
    MatIcon,
    ReactiveFormsModule,
    FontAwesomeModule,
    UtilsModule
  ],
})
export class PasswordResetModule { }
