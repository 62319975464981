// color.service.ts

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ColorService {
  public colors = {
    primary_color: "#007bff",
    secondary_color: "#6c757d",
  };

  constructor() {}
}
