<div class="email-form-content">
    <div class="row">
        <div class="col-sm-12 col-md-4 col-lm-4 text-left">
            <div (click)="onPop()" class="back-button">
                <fa-icon class="arrow-left-icon" [icon]="faChevronLeft" style="color: white;"></fa-icon>
                <span class="text mx-1">
                    Volver
                </span>

            </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lm-4">
            <div class="text-center">
                <span class="text-register text-white">¿Olvidaste tu </span>
                <span class="text-register text-ligth" style="color: var(--primary);">contraseña?</span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <p class="text subtitle">
                    Ingresa tu correo electrónico para restablecer
                    la contraseña de tu cuenta
                </p>
            </div>
            <form [formGroup]="emailForm" (ngSubmit)="onContinueButtonTap()">
                <label for="email" class="text input-field-label"> Correo electrónico</label>
                <div class="input-field rounded rounded-4 align-items-center"
                    [class]="emailFieldError != ''?'input-field-error':''">
                    <img class="form-icon" [src]="emailIcon" alt="">
                    <input class="input-control rounded rounded-4" placeholder="Ingresar" type="text"
                        formControlName="email" (input)="onEmailFieldChange()">
                </div>
                <div *ngIf="emailFieldError != ''">
                    <img class="info-icon" [src]="infoIcon" alt="">
                    <label for="email" class="info-text input-field-label">{{emailFieldError}}</label>
                </div>

                <br>
                <br *ngIf="emailFieldError != ''">
                <button class="main-button" [class]="emailForm.valid?'main-button-active':'main-button-disabled'"
                    type="submit">Enviar</button>
            </form>
        </div>
        <div class="col-4"></div>
    </div>
</div>