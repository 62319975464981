import { Component } from '@angular/core';
import { AppResourcesService } from '../../../../../core/resources/app-resources.service';


@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrl: './password.component.css'
})
export class PasswordResetComponent {
  treebuLogo!: string;
  businessLogo!: string;

  constructor() {
    this.treebuLogo = AppResourcesService.logos.treebu_logo;
    this.businessLogo = AppResourcesService.logos.business_logo;
  }
}
