import { Injectable } from "@angular/core";
import { Either } from "../../core/helpers/either";
import { Failure } from "../../core/helpers/failure";
import { UseCase } from "../../core/helpers/use-case";
import { UserRepository } from "@domain/repository/user-repository";
import { ValidateVerificationOtpEmailBody } from "@domain/models/validate-verification-otp-email-body";

@Injectable({
    providedIn: 'root'
})
export class ValidateVerificationOtpEmailUsecase implements UseCase<boolean, ValidateVerificationOtpEmailBody>{
    constructor(private userRepository: UserRepository) { }

    async execute(params: ValidateVerificationOtpEmailBody): Promise<Either<Failure, boolean>> {
        return await this.userRepository.validateVerificationOtpEmail(params);
    }
}