import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ToastsService } from "@presentation/utils/molecules/toasts/toasts.service";
import { PasswordModalService } from "../../../../../../utils/organisms/password-modal/services/password-modal.service";
import { UpdateUserPasswordUsecase } from "@domain/usecases/update-user-password-usecase";
import { Router } from "@angular/router";
import { UpdateUserPasswordBody } from "@domain/models/update-user-password-body";

@Component({
  selector: "app-new-password",
  templateUrl: "./new-password.component.html",
  styleUrl: "./new-password.component.scss",
})
export class NewPasswordComponent implements OnInit {
  email: string = "";
  form: FormGroup;
  showPasswordModal: boolean = false;
  passwordModalOpened: boolean = false;

  constructor(
    private location: Location,
    private passwordModalService: PasswordModalService,
    private formBuilder: FormBuilder,
    private toastsService: ToastsService,
    private router: Router,
    private updateUserPasswordUsecase: UpdateUserPasswordUsecase
  ) {
    this.getScreenParameters();
    this.createForm();
  }

  ngOnInit(): void {
    this.passwordModalService.$modal.subscribe((valor) => {
      this.showPasswordModal = valor;
    });
  }

  getScreenParameters() {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.email = navigation.extras.state["email"];
    }
  }

  openPasswordModal() {
    //Modal will only be shown once
    if (!this.passwordModalOpened) {
      this.showPasswordModal = true;
      this.passwordModalOpened = true;
    }
  }

  createForm() {
    this.form = this.formBuilder.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
            ),
          ],
        ],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validator: this.ConfirmPasswordValidator("password", "confirmPassword"),
      }
    );
  }

  ConfirmPasswordValidator(
    password: string,
    confirmPassword: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value1 = control.get(password)?.value;
      const value2 = control.get(confirmPassword)?.value;

      if (value1 !== null && value2 !== null && value1 !== value2) {
        return { stringsNotMatch: true };
      }

      return null;
    };
  }

  async updatePasswordFlow() {
    if (!this.form.valid) return;

    this.toastsService.showLoadingToast();
    let result = await this.updateUserPassword({
      email: this.email,
      newPassword: this.form.get("password")?.value,
    });
    this.toastsService.hideLoadingToast();
    if (!result) {
      this.toastsService.showInfoToast(
        "No se pudo cambiar la contraseña. Intenta nuevamente."
      );
    }

    this.goBack(3);
    this.toastsService.showInfoToast("¡Tu contraseña ha sido actualizada!");
  }

  async updateUserPassword(data: UpdateUserPasswordBody): Promise<boolean> {
    let result = await this.updateUserPasswordUsecase.execute(data);
    return result.fold(
      (l) => false,
      (r) => true
    );
  }

  goBack(times: number) {
    for (let i = 0; i < times; i++) {
      this.location.back();
    }
  }
}
